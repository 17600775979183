import type { JSX } from 'react';
import { ContactUs as ContactUsComponent } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type ContactUsProps = SliceComponentProps<Content.ContactUsSlice>;

const ContactUs = ({ slice }: ContactUsProps): JSX.Element => (
  <ContactUsComponent {...slice} />
);

export default ContactUs;
